import request from '../utils/request';

export default class AuthApi {
    public async LoginAsync(userName: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            request({
                url: '/api/auth/login',
                method: 'post',
                data: {
                    userName,
                    password
                }
            }).then((res) => resolve(res.data)).catch((error) => reject(error));
        });
    }
}
