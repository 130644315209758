



































import {Vue, Component} from 'vue-property-decorator';
import LoginDialog from '../login-dialog.vue';


@Component({
    components: {
        LoginDialog
    }
})
export default class MainLayoutNavBar extends Vue {
    private visible = false;

    get isLogin() {
        return this.$store.getters.isLogin;
    }

    public async handleCommand(v: string) {
        if (v === 'login') {
            this.visible = true;
        } else if (v === 'logout') {
            location.reload();
            Vue.prototype.$abp.auth.clearToken();
        }
    }

    private handlerClose() {
        this.visible = false;
    }

}
