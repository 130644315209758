import Vue from 'vue';
import Vuex from 'vuex';

import application from './modules/application';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application
  },
  getters
});
