import {ExtensibleEntityDto} from '../dto/ExtensibleEntityDto';
import request from '@/utils/request';
import ReadOnlyAppService from './ReadOnlyAppService';

export default class CrudAppService<TGetOutputDto extends ExtensibleEntityDto<TKey>, TGetListOutputDto extends ExtensibleEntityDto<TKey>, TKey, TGetListInput, TCreateInput, TUpdateInput> extends ReadOnlyAppService<TGetOutputDto, TGetListOutputDto, TKey, TGetListInput> {
  constructor(url: string) {
    super(url);
  }

  public async CreateAsync(input: TCreateInput): Promise<TGetOutputDto> {
    return new Promise<TGetOutputDto>((resolve, reject) => {
      request({
        url: `${this.url}`,
        method: 'post',
        data: input
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }

  public async UpdateAsync(id: TKey, input: TUpdateInput): Promise<TGetOutputDto> {
    return new Promise<TGetOutputDto>((resolve, reject) => {
      request({
        url: `${this.url}/${id}`,
        method: 'put',
        data: input
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }

  public async DeleteAsync(id: TKey): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}`,
        method: 'delete'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }
}
