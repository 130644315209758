import CrudAppService from '../framework/api/CrudAppService';
import {
  CompareSystemDto,
  CreateOrUpdateCompareSystemDto,
  GetListCompareSystemDto
} from '@/framework/models/compareSystem';
import {Guid} from 'guid-typescript';

export default class SystemApi extends CrudAppService<CompareSystemDto, CompareSystemDto, Guid, GetListCompareSystemDto, CreateOrUpdateCompareSystemDto, CreateOrUpdateCompareSystemDto> {
  constructor() {
    super('/api/compareSystem');
  }
}
