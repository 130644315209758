import axios from 'axios';
import {Message} from 'element-ui';
import abp from './abp';

const service = axios.create({
    // baseURL: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_BASE_API,
    timeout: 60000,
});


service.interceptors.request.use((config) => {
    const antiForgeryToken = abp.security.antiForgery.getToken();
    if (antiForgeryToken) {
        config.headers[abp.security.antiForgery.tokenHeaderName] = antiForgeryToken;
    }

    const token = abp.auth.getToken();
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});


service.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.code === 'ECONNABORTED') {
        Message({
            type: 'error',
            message: '连接超时'
        });
    } else if (error.response) {
        const response = error.response;

        let title = response.status;
        let message;
        if (response.statusText) {
            title += (' ' + response.statusText);
        }

        const data = response.data;
        if (data?.error) {
            if (data.error.details) {
                message = response.data.error.details;
            } else if (data.error.message) {
                message = response.data.error.message;
            }
        }

        if (!message) {
            switch (error.response.status) {
                case 400:
                    message = '发出的请求有错误，服务器没有进行新建或修改数据的操作。';
                    break;
                case 401:
                    message = '用户没有权限（令牌、用户名、密码错误）。';
                    break;
                case 403:
                    // errorMessage = '用户得到授权，但是访问是被禁止的。';
                    message = `${error.config.url} 访问被禁止。`;
                    break;
                case 404:
                    message = '发出的请求针对的是不存在的记录，服务器没有进行操作。';
                    break;
                case 500:
                    message = '服务器发生错误，请检查服务器。';
                    break;
                case 502:
                    message = '网关错误。';
                    break;
                case 503:
                    message = '服务不可用，服务器暂时过载或维护。';
                    break;
                case 504:
                    message = '网关超时。';
                    break;
                default:
                    message = '未知错误';
                    break;
            }
        }

        Message({
            type: 'error',
            message
        });
    } else if (error.message) {
        Message({
            type: 'error',
            message: error.message
        });
    } else {
        Message({
            type: 'error',
            message: '请求错误'
        });
    }

    return Promise.reject(error);
});

export default service;
