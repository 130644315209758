import request from '../utils/request';


export default class AbpApi {
    public async GetApplicationConfiguration(): Promise<any> {
        return new Promise((resolve, reject) => {
            request({
                url: '/api/abp/application-configuration',
                method: 'get',
                params: {
                    'api-version': '1.0'
                }
            }).then((res) => resolve(res.data)).catch((error) => reject(error));
        });
    }
}
