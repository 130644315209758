




































import {Vue, Component} from 'vue-property-decorator';
import MainLayoutNavBar from './MainLayoutNavBar.vue';
import SystemApi from '@/api/SystemApi';
import {CompareSystemDto, GetListCompareSystemDto} from '@/framework/models/compareSystem';
import {PageResultDto} from '@/framework/dto/ResultDto';

@Component({
  components: {
    MainLayoutNavBar
  }
})
export default class MainLayout extends Vue {

  private sysApi = new SystemApi();
  private sysListQuery = new GetListCompareSystemDto();
  private result: PageResultDto<CompareSystemDto> = {
    items: new Array<CompareSystemDto>(),
    totalCount: 0
  };

  get isLogin() {
    return this.$store.getters.isLogin;
  }

  get currentIndex() {
    return this.$route.path;
  }

  public async mounted() {
    await this.getCompareList();
  }

  private async getCompareList() {
    this.result = await this.sysApi.getListAsync(this.sysListQuery);
  }
}
