import {ExtensibleEntityDto} from '../dto/ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from '../dto/PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface CompareSystemDto extends ExtensibleEntityDto<Guid> {
  name: string;
}

export class CreateOrUpdateCompareSystemDto {
  public id: Guid = Guid.createEmpty();
  public name: string = '';
}

export class GetListCompareSystemDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
}
