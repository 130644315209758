// @ts-nocheck

import AbpApi from '@/api/AbpApi';
import {assignAbp} from '@/utils/abp';

const api = new AbpApi();

const application = {
    namespaced: true,
    state: {
        applicationConfiguration: {}
    },
    mutations: {
        SET_APPLICATION_CONFIGURATION(state: any, configuration: any) {
            state.applicationConfiguration = configuration;
        }
    },
    actions: {
        async getApplicationConfiguration(context: any): Promise<any> {
            return new Promise(async (resolve, reject) => {
                try {
                    const result = await api.GetApplicationConfiguration();
                    assignAbp();
                    context.commit('SET_APPLICATION_CONFIGURATION', result);
                    resolve(result);
                } catch {
                    reject();
                }
            });
        }
    }
};

export default application;
