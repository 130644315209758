import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/element';
import './assets/style.less';

Vue.config.productionTip = false;

import abp from './utils/abp';

Vue.prototype.$abp = abp;

router.beforeEach(async (to, from, next) => {
    await store.dispatch('application/getApplicationConfiguration');
    next();
});

import axios from 'axios';
import request from './utils/request';


const setting_url = '/appsettings.json';
axios.get(setting_url).then((res: any) => {
    request.defaults.baseURL = res.data.ServerUrl;
    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
});
