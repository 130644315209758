




























import {Vue, Component, Prop} from 'vue-property-decorator';
import AuthApi from '../api/auth';
import request from '@/utils/request';

@Component({})
export default class LoginDialog extends Vue {
  @Prop({required: true, default: () => false}) private visible!: boolean;
  private api = new AuthApi();
  private dataForm = {
    userName: '',
    password: ''
  };
  private rules = {
    userName: [
      {required: true, message: '请输入用户名', trigger: 'blur'},
    ],
    password: [
      {required: true, message: '请输入密码', trigger: 'blur'},
    ]
  };

  private async mounted() {
    await request('/api/xsrf-token');
  }

  private handlerBeforeClose() {
    this.$emit('handlerClose');
  }

  private async handlerSubmit() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return false;
      }
    });

    const result = await this.api.LoginAsync(this.dataForm.userName, this.dataForm.password);
    if (result.succeeded) {
      // 设置Cookie过期时间为一天
      const date = new Date();
      const dateDate = date.setDate(date.getDate() + 1);
      const newDate = new Date(dateDate);
      Vue.prototype.$abp.auth.setToken(result.access_Token, newDate);
      this.handlerBeforeClose();
      location.reload();
    } else {
      this.$message({
        message: result.error?.message ?? '未知错误',
        type: 'warning',
      });
    }
  }
}
