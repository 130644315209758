import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import MainLayout from '../components/layout/MainLayout.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Home',
        component: MainLayout,
        children: [
            {
                path: '/:cid?',
                name: '首页',
                component: () => import('../views/compares/index.vue')
            },
        ],
    },
    {
        path: '/system',
        component: MainLayout,
        name: 'system',
        children: [
            {
                path: 'index',
                component: () => import('../views/compares/system.vue')
            },
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
